import React from 'react'
import Layout from '../components/layout'
import MobileAppDeveloper from '../components/categories/mobile-app-developer'
import Seo from '../components/seo'

const MobileAppDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <MobileAppDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Mobile App Developer Freelancers | Codestaff'
  const description =
    'Hire the best Mobile App Developer freelancers at Codestaff. Get the top 1% of Mobile App Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default MobileAppDeveloperPage
