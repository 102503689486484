import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is The Difference Between Native App Development Vs Cross-platform Development?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Native app development uses default language and IDE for iOS and Android, i.e. JAVA/Kotlin with Android Studio for Android and Objective C, Swift with Xcode for iOS. On the other hand, cross-platform offers a framework in which coding is done once and can be used to deploy the app in Android, iOS and Window phone."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What platform should we target to launch Android or iOS?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "iPhone and Android smartphones are growing really popular, so we recommend that you launch the app in iOS and Android. Codestaff will help you from start to finish."
        }
    }
}

const MobileAppDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Mobile App Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Mobile App Developers. Top
                                    companies and start-ups choose Codestaff professional Mobile
                                    App Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Mobile App Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE MOBILE APP DEVELOPERS' banner='/mobile-app-developer.png' bannerAlt='mobile app developer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default MobileAppDeveloper